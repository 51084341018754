import { Address, TypesOfShippings } from '@pedix-workspace/utils';

export const getOrderAddress = (
  address: Address,
  shippingOptionType: TypesOfShippings | undefined,
  options: { includeHints: boolean },
): string | null => {
  if (![TypesOfShippings.DELIVERY, TypesOfShippings.PARCEL].includes(shippingOptionType)) {
    return null;
  }

  let text = address.addressDetails ? address.addressDetails.fullAddress : address.street;

  if (options.includeHints) {
    const addressHints = getOrderAddressHints(address, shippingOptionType);

    if (addressHints) {
      return `${text} - ${addressHints}`;
    }
  }

  return text;
};

export const getOrderAddressHints = (
  address: Address,
  shippingOptionType: TypesOfShippings | undefined,
): string | null => {
  if (![TypesOfShippings.DELIVERY, TypesOfShippings.PARCEL].includes(shippingOptionType)) {
    return null;
  }

  const addressHints: string[] = [];

  if (address.placeName) {
    addressHints.push(address.placeName);
  }
  if (address.lot) {
    addressHints.push(address.lot);
  }
  if (address.floorAndNumber) {
    addressHints.push(address.floorAndNumber);
  }
  if (address.references) {
    addressHints.push(address.references);
  }
  if (addressHints.length > 0) {
    return addressHints.join(', ');
  }
  return null;
};
